//
// Form Wizard
//


.form-wizard-wrapper {
  label {
    font-size: 14px;
    text-align: right;
  }
}

.wizard{

  // step
  .steps{
    >ul{
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      > a, > li{
        flex-basis: 0;
        flex-grow: 1;
      }

      >li{
        width: 100%;

        a{
          display: block;
          padding: .5rem 0.5rem;
          color: var(--#{$prefix}gray-600);
          font-weight: $fw-medium;

          background-color: rgba($primary, 0.1);
        }
      }

      .current-info{
        position: absolute;
        left: -999em;
    }

    
    }

    .number {
      display: inline-block;
      width: 29px;
      height: 29px;
      line-height: 29px;
      border: 2px solid $primary;
      color: $primary;
      text-align: center;
      border-radius: 50%;
      margin-right: .2rem;
    }

    .current {
      a, a:active, a:hover {
        background-color: rgba($primary, 0.2);
        color: var(--#{$prefix}gray-700);
        .number {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  // content
  >.content {
    background-color: transparent;
    padding: 14px;
    margin-top: 0;
    border-radius: 0;
    min-height: 150px;
    > .title{
      position: absolute;
      left: -999em;
    }
    >.body {
      width: 100%;
      height: 100%;
      padding: 14px 0 0;
      position: static
    }
  }

  // actions

  >.actions {
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
    > ul {
        display: block;
        text-align: right;
        padding-left: 0;
          > li{
            display: inline-block;
            margin: 0 0.5em;

          }
      }
      a, a:active, a:hover {
        background-color: $primary;
        border-radius: 4px;
        padding: 8px 15px;
        color: $white;
      }

      .disabled {
        a, a:active, a:hover {
          opacity: .65;
          background-color: $primary;
          color: $white;
          cursor: not-allowed;
        }
      }
  }

  // verticl wixard
  &.vertical-wizard{
    display: flex;
    flex-wrap: wrap;

    // step
    .steps{
      >ul{
        flex-direction: column;

        >li{
            width: 100% !important;
        }
        
      }
    }

    .steps, .content, .actions{
      width: 100%;
    }

    .steps{
        @media (min-width: 1200px) {
          width: 25%;
      }
    }

      .content{
        padding: 24px;
        @media (min-width: 1200px) {
          width: 75%;
          padding: 12px 24px;
      }

      >.body{
        padding: 0;
      }
      
    }
  }
}
