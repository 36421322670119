// 
// general-rtl.scss
//

// html {
// }

body.rtl {
    direction: rtl;
    text-align: right;
}